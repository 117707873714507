import React from 'react'
import { graphql } from 'gatsby'
import Listing from './listing'
import startCase from 'lodash/startCase'

class Series extends React.Component {
  render () {
    const posts = this.props.data.allMdx.edges
    const { basePath, series, currentPage, numPages } = this.props.pageContext
    const seriesName = startCase(series)
    return (
      <Listing
        title={`${seriesName} Tutorials${currentPage > 1 ? ` Page ${currentPage}`: ''}`}
        description={`${seriesName} Tutorials${currentPage > 1 ? ` Page ${currentPage}`: ''}. Learn ${seriesName} online. Find the latest codes, tutorials, demos and practical guides on ${seriesName}.`}
        siteMeta={this.props.data.site.siteMetadata}
        location={this.props.location}
        basePath={basePath}
        posts={posts}
        currentPage={currentPage}
        numPages={numPages} />
    )
  }
}

export default Series

export const seriesQuery = graphql`
  query seriesQuery($series: String, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        name
        title
        description
        author
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: { 
          type: { eq: "post" } 
          status: { ne: "draft" }
          series: { in: [$series] }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
            description
            categories
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(width: 650)
              }
            }
          }
        }
      }
    }
  }
`